const config = require('./config.js');

export const URLAPI = config.ENV_URL_API;
export const WEBSOCKET = config.ENV_URL_WS;

export const USERNAME = config.ENV_BACKEND_USER;
export const PASSWORD = config.ENV_BACKEND_PASS;


export const TOKEN_PATH = "/user/token/";
export const USERS_PATH = "/user/list/";
export const USERS_UPDATE_PATH = "/user/update/";
export const USERS_UPDATE_PASSWORD_PATH = "/user/update-password/";
export const USERS_CREATE_PATH = "/user/create/";
export const USER_GROUPS_PATH = "/user/group-list/";
export const USER_GET_PATH = "/user/get/";
export const USER_GET_ALL_PERMISSIONS_PATH = '/user/permission-list/';
export const USER_CREATE_GROUP_PATH = '/user/group-create/';
export const USER_UPDATE_GROUP_PATH = '/user/group-update/';
export const USER_ACTIVATE_PATH = '/user/activate/';
export const USER_LOGOUT_PATH = '/user/logout/';
export const USER_PASSWORD_RESET_PATH = '/user/password/reset/';
export const USER_PASSWORD_RESET_CONFIRM_PATH = '/user/password/reset/confirm/';
export const VALUES_HOME_CLIENTS_PATH = '/user/values-clients/';

export const FAMILY_PATH = "/inventory/families-list/";
export const FAMILY_CREATE_PATH = "/inventory/family-create/";
export const FAMILY_UPDATE_PATH = "/inventory/family-update/";
export const FAMILY_ATTRIBUTE_CREATE_PATH = "/inventory/family-attribute-create/";
export const FAMILY_ATTRIBUTE_DELETE_PATH = "/inventory/family-attribute-delete/";

export const PAYMENT_TYPE_PATH = "/payment/type-list/";
export const PAYMENT_TYPE_CREATE_PATH = "/payment/type-create/";
export const PAYMENT_TYPE_UPDATE_PATH = "/payment/type-update/";
export const PAYMENT_TYPE_DELETE_PATH = "/payment/type-delete/";

export const SHOP_PATH = "/shop/list/";
export const SHOP_CREATE_PATH = "/shop/create/";
export const SHOP_UPDATE_PATH = "/shop/update/";
export const SHOP_DELETE_PATH = "/shop/delete/";


export const ATTRIBUTE_PATH = "/inventory/attributes-list/";
export const ATTRIBUTE_CREATE_PATH = "/inventory/attribute-create/";
export const ATTRIBUTE_UPDATE_PATH = "/inventory/attribute-update/";
export const ATTRIBUTE_DELETE_PATH = "/inventory/attribute-delete/";
export const ATTRIBUTE_VALUE_CREATE_PATH = "/inventory/attribute-value-create/";
export const ATTRIBUTE_VALUE_DELETE_PATH = "/inventory/attribute-value-delete/";

export const CATEGORY_PATH = "/inventory/categories-list/";
export const CATEGORY_CREATE_PATH = "/inventory/category-create/";
export const CATEGORY_UPDATE_PATH = "/inventory/category-update/";

export const GENDER_PATH = "/inventory/genders-list/";
export const GENDER_CREATE_PATH = "/inventory/gender-create/";
export const GENDER_UPDATE_PATH = "/inventory/gender-update/";
export const GENDER_DELETE_PATH = "/inventory/gender-delete/";

export const MANUFACTURER_PATH = "/inventory/manufacturers-list/";
export const MANUFACTURER_CREATE_PATH = "/inventory/manufacturer-create/";
export const MANUFACTURER_UPDATE_PATH = "/inventory/manufacturer-update/";
export const MANUFACTURER_DELETE_PATH = "/inventory/manufacturer-delete/";

export const BANK_ACCOUNT_PATH = "/transaction/bank-account-list/";
export const BANK_ACCOUNT_CREATE_PATH = "/transaction/bank-account-create/";
export const BANK_ACCOUNT_UPDATE_PATH = "/transaction/bank-account-update/";
export const BANK_ACCOUNT_DELETE_PATH = "/transaction/bank-account-delete/";

export const CITY_PATH = "/location/cities-list/";
export const CITY_CREATE_PATH = "/location/city-create/";
export const CITY_UPDATE_PATH = "/location/city-update/";
export const CITY_DELETE_PATH = "/location/city-delete/";

export const ITEM_PATH = "/inventory/items-list/";
export const CATALOGUE_ITEM_PATH = "/inventory/catalogue-items/";
export const VALUES_ATTRIBUTES_PATH = "/inventory/items-values-attributes/";
export const VALUES_FAMILY_PATH = "/inventory/values-family/";
export const ITEM_CREATE_PATH = "/inventory/item-create/";
export const ITEM_UPDATE_PATH = "/inventory/item-update/";
export const ITEM_IMAGE_DELETE_PATH = "/inventory/item-image-delete/";

export const SHOPPING_CART_PATH = "/transaction/shopping-cart-list/";
export const SHOPPING_CART_CREATE_PATH = "/transaction/shopping-cart-create/";
export const SHOPPING_CART_UPDATE_PATH = "/transaction/shopping-cart-update/";
export const ITEM_SHOPPING_CART_DELETE_PATH = "/transaction/item-shopping-cart-delete/";

export const QUOTATION_PATH = "/transaction/quotations-list/";
export const QUOTATION_ADMIN_PATH = "/transaction/quotations-admin-list/";
export const QUOTATION_CREATE_PATH = "/transaction/quotation-create/";
export const QUOTATION_UPDATE_PATH = "/transaction/quotation-update/";
export const QUOTATION_UPDATE_CLIENT_PATH = "/transaction/quotation-update-client/";
export const QUOTATION_CONFIRM_PATH = "/transaction/quotation-confirm/";
export const QUOTATION_ADVANCE_PAYMENT_PATH = "/transaction/quotation-advance-payment/";
export const QUOTATION_CONFIRM_ADVANCE_PAYMENT_PATH = "/transaction/quotation-confirm-advance-payment/";
export const QUOTATION_CANCEL_ADVANCE_PAYMENT_PATH = "/transaction/quotation-cancel-advance-payment/";
export const QUOTATION_REPORT_PATH = "/transaction/quotation/report/";
export const QUOTATION_REPORT_CHECK_PATH = "/transaction/quotation/report/check/";
export const QUOTATION_DOWNLOAD_PDF_PATH = "/transaction/quotation/download/pdf/";
export const PAY_ORDER_DOWNLOAD_PDF_PATH = "/transaction/pay-order/download/pdf/";
export const VALUES_QUOTATIONS_PATH = "/transaction/values-quotations/";


export const ORDER_PATH = "/transaction/order-list/";
export const ORDER_ADMIN_PATH = "/transaction/order-admin-list/";
export const ORDER_PAYMENT_PATH = "/transaction/order-payment/";
export const ORDER_UPDATE_PATH = "/transaction/order-update/";
export const ORDER_DOWNLOAD_PDF_PATH = "/transaction/order/download/pdf/";
export const ORDER_REPORT_PATH = "/transaction/order/report/";
export const ORDER_REPORT_CHECK_PATH = "/transaction/order/report/check/";

export const BANNER_IMAGE_PATH = "/images-list/";
export const BANNER_IMAGE_CREATE_PATH = "/image-create/";
export const BANNER_IMAGE_UPDATE_PATH = "/image-update/";

export const STATES_PATH = "/states-list/";

export const APP_NAME = "Fabricaciones";


const BASE_EN_ESPERA = "En espera";
const BASE_COTIZADO = "Cotizado";

export const get_status = (status) => {
    switch (status) {
        case BASE_EN_ESPERA:
            return 'warning';
        case BASE_COTIZADO:
            return 'success';
        default:
            return 'primary';
    }
}